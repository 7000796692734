//Datepicker color overrides

.react-datepicker {
  font-family: inherit;
  border-color: rgba(0, 0, 0, 0.15) !important;
  .react-datepicker__header {
      background-color: $light;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
  .react-datepicker__day {
    border: none;
    outline: none;
  }
  .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header, .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color: $dark;
    &:hover {
      background-color: $light;
    }
  }
  .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
    color: $muted;
    &:hover {
      background-color: transparent;
    }
  }
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background-color: $primary;
    color: $white;
    &:hover {
      background-color: shade-color($primary, 10%);
    }
  }
  .react-datepicker__day--keyboard-selected {
    background-color: #fff;
  }
  .react-datepicker__navigation--next {
    border-left-color: $muted;
    &:hover {
      border-left-color: shade-color($muted, 10%);
    }
  }
  .react-datepicker__navigation--previous {
    border-right-color: $muted;
    &:hover {
      border-right-color: shade-color($muted, 10%);
    }
  }
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: $light !important;
    &::before {
      border-bottom-color: rgba(0, 0, 0, 0.15);
    }
}
.react-datepicker-popper {
   z-index: 10 !important;

  h2 {
    font-size: 0.944rem !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  .react-datepicker__triangle {
    left: 50px !important;
  }
}
.decision-tree-header {
  .react-datepicker-wrapper {
    input {
      margin: 0;
      border-width: 0;
      padding: 0;
      visibility: hidden;
      max-width: 135px;
      background-color: transparent;
    }
  }
  .react-datepicker__tab-loop {
    display: inline-block;
  }
}
.react-datepicker-wrapper {
  display: block !important;
}

//React datepicker cancel button overrides
.react-datepicker__close-icon::after {
  background-color: $primary !important;
}
