.learn-table {
  thead, tbody, tfoot {
    th:first-child,
    td:first-child  {
      text-align: left;

      div.rounded {
        justify-content: left;
      }
    }

    th,
    td {
      text-align: center;
      min-width: 112px;
      line-height: 1rem;

      div.rounded {
        min-height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  thead {
    th:first-child {
      min-width: 280px;
      width: 100%;
    }
  }
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: $white;
}

.segmentation-table {
  td {
    padding: 0;
  }

  td:first-child {
    text-align: left;
  }

  td:last-child {
    font-weight: 500;
    text-align: right;
  }
}
