#root {
  &.hidden {
    display: none !important;
  }
}
#pdf-root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  iframe {
    width: 100%;
    height: 100%;
  }
  &.hidden {
    display: none !important;
  }
}

