@import 'mixins';

.ztree *:not(.btn) {
  font-size: inherit;
  font-family: inherit;
  background-color: inherit;

  *:not(.dropdown-menu) {
    border-radius: 36px;
  }
}

.ztree {
  color: $dark;
  background-color: transparent;

  .dropdown-menu.show {
    background-color: $white;

    button.dropdown-item {
      &:active, &:focus, &:hover {
        border-radius: 0;
        background-color: #f0f6fc;
        color: #1f2f3f;
      }

    }
  }


}

.ztree li ul {
  margin: 0px;
  padding: 0 0 0 22px;
}

.ztree li {
  position: relative;

  .playbook-icon,
  .comment-icon {
    font-size: 1.167rem;
    vertical-align: middle;
  }

  .btn {
    &:active,
    &:focus,
    &:hover {
      .fas,
      .fa-pencil-alt,
      .fa-lg,
      .fa-sm {
        background-color: inherit !important;
      }
    }

    .fa-lg {
      font-size: 1.33333em;
      background-color: inherit !important;
    }

    .fa-sm {
      font-size: 1.08em;
      background-color: inherit !important;
    }
  }
}

.ztree.view-mode {
  li a, li span.button {
    cursor: default;
  }
}

.ztree li a {
  height: 44px;
  color: $dark;
  padding: 0;
  width: calc(100% - 22px);

  &:hover {
    text-decoration: unset;
  }

  .selectable-area {
    line-height: 36px;

    &.decision-order {
      padding-left: 3px;
    }

    &.active {
      display: block;
      background-color: $light;
      height: auto;
      opacity: unset;
      border-radius: 25px !important;
    }
    &.default-cursor:hover {
      cursor: default;
    }
  }

  &.curSelectedNode {
    background-color: $light;
    color: $dark;
    opacity: unset;
    height: 36px;
    margin: 4px 0;

    .selectable-area {
      color: $dark;
      &.copied {
        background-color: $secondary;
      }
    }
  }

  .copied {
    background-color: $secondary;
    color: $dark;
    padding-top: 0px;
    padding-bottom: 2px;
    border-radius: 8px;
  }

  .curSelectedNode_Edit {
    padding-top: 2px;
    background-color: transparent;
    color: #ffff00;
    height: 25px;
    border: 1px #666 solid;
    opacity: 0.8;
  }

  .tmpTargetNode_inner {
    padding-top: 0px;
    background-color: #aaa;
    color: #ffff00;
    height: 25px;
    border: 1px #666 solid;
    opacity: 0.8;
    filter: alpha(opacity=80);
  }

  input.rename {
    color: $white;
    background-color: $dark;
    font-size: 14px;
  }
}

.ztree li span {
  line-height: 17px;
  padding: 0 0 0 3px;
  margin: 0;
  background-color: transparent;
}

.ztree li span.button {
  vertical-align: middle;
  padding-top: 8px;
  padding-right: 20px;
}

.ztree li span.button::before {
  color: $primary;
  font-family: "Font Awesome 6 Pro";
  padding-top: 10px;
  font-weight: 900;
}

.ztree li span.button.chk {
  margin: 0px;
  cursor: auto;
  width: 12px;
  display: inline-block;
  padding-top: 22px;
  padding-left: 4px;
}

.ztree li span.button.chk.checkbox_false_full::before {
  content: "\f096";
}

.ztree li span.button.chk.checkbox_false_full_focus::before {
  content: "\f096";
  color: #ffff00;
}

.ztree li span.button.chk.checkbox_false_part::before {
  content: "\f096";
  color: #aaaaaa;
}

.ztree li span.button.chk.checkbox_false_part_focus::before {
  content: "\f096";
  color: #cad96c;
}

.ztree li span.button.chk.checkbox_false_disable::before {
  content: "\f096";
  color: #808080;
}

.ztree li span.button.chk.checkbox_true_full::before {
  content: "\f046";
}

.ztree li span.button.chk.checkbox_true_full_focus::before {
  content: "\f046";
}

.ztree li span.button.chk.checkbox_true_part::before {
  content: "\f14a";
}

.ztree li span.button.chk.checkbox_true_part_focus::before {
  content: "\f14a";
  color: #ffff00;
}

.ztree li span.button.chk.checkbox_true_full_focus::before {
  content: "\f046";
  color: #ffff00;
}

.ztree li span.button.chk.checkbox_true_part::before {
  content: "\f046";
  color: #aaaaaa;
}

.ztree li span.button.chk.checkbox_true_part_focus::before {
  content: "\f046";
  color: #cad96c;
}

.ztree li span.button.chk.checkbox_true_disable::before {
  content: "\f046";
  color: #808080;
}

.ztree li span.button.chk.radio_false_full::before {
  content: "\f10c";
}

.ztree li span.button.chk.radio_false_full_focus::before {
  content: "\f10c";
  color: #ffff00;
}

.ztree li span.button.chk.radio_false_part::before {
  content: "\f10c";
  color: #aaaaaa;
}

.ztree li span.button.chk.radio_false_part_focus::before {
  content: "\f10c";
  color: #ffff00;
}

.ztree li span.button.chk.radio_false_disable::before {
  content: "\f1db";
  color: #808080;
}

.ztree li span.button.chk.radio_true_full::before {
  content: "\f192";
}

.ztree li span.button.chk.radio_true_full_focus::before {
  content: "\f192";
  color: #ffff00;
}

.ztree li span.button.chk.radio_true_part::before {
  content: "\f192";
  color: #aaaaaa;
}

.ztree li span.button.chk.radio_true_part_focus::before {
  content: "\f192";
  color: #aaaaaa;
}

.ztree li span.button.chk.radio_true_disable::before {
  content: "\f1db";
  color: #808080;
}

.ztree li span.button.switch {
  width: 15px;
  height: 25px;
  padding-right: 22px;
  position: relative;
}

.ztree li span.button.switch.level0 {
  display: inline;
  position: relative;
  padding: 0;
  width: 0;
}

.ztree li span.button.switch.level0.root_open {
  &::before {
    content: "";
    display: block;
    height: 0;
    margin-left: -5px;
    width: 21px;
    position: absolute;
    border-bottom: solid thin #9AA9B8;
    bottom: -28px;
    transform: rotate(270deg);
  }
}

.ztree li span.button.root_open,
.ztree li span.button.root_close,
.ztree li span.button.roots_open,
.ztree li span.button.roots_close,
.ztree li span.button.center_open,
.ztree li span.button.center_close,
.ztree li span.button.bottom_open,
.ztree li span.button.bottom_close {
  padding-top: 0;
}

.ztree li span.button.roots_open::before {
  content: "\f077";
  padding-top: 22px;
  padding-left: 4px;
  display: inline-block;
  color: $primary;
}

.ztree li span.button.roots_close::before {
  content: "\f054";
  padding-top: 22px;
  padding-left: 4px;
  display: inline-block;
  color: $primary;
}

.ztree li span.button.center_open {
  position: relative;
  z-index: 1;

  &::before {
    content: "\f077";
    display: block;
    position: absolute;
    padding-left: 4px;
    padding-bottom: 8px;
    bottom: -7px;
    color: $primary;
    background-color: $white;
  }

  &::after {
    content: "";
    display: block;
    margin-left: 18px;
    width: 7px;
    position: absolute;
    border-bottom: solid thin $gray;
    bottom: 2px;
  }
}

.ztree li span.button.center_close::before {
  content: "\f078";
  padding-top: 22px;
  padding-left: 4px;
  display: inline-block;
  color: $primary;
}

.ztree li span.button.bottom_open{
  position: relative;

  &::before {
    content: "\f077";
    padding-top: 22px;
    padding-left: 4px;
    display: inline-block;
    color: $primary;
  }

  &::after {
    content: "";
    display: block;
    height: 1px;
    margin-left: 18px;
    width: 7px;
    position: absolute;
    border-bottom: solid thin $gray;
    border-radius: 0;
  }
}

.ztree li span.button.bottom_close::before {
  content: "\f078";
  padding-top: 22px;
  padding-left: 4px;
  display: inline-block;
  color: $primary;
}

.ztree li span.button.root_docu {
  background: none;
}

.ztree li span.button.roots_docu::before {
  content: "\f022";
  padding-left: 4px;
  display: inline-block;
  color: $primary;
}

.ztree li span.button.center_docu{
  &::before {
    content: "";
    display: block;
    height: 0;
    margin-left: -13px;
    width: 57px;
    position: absolute;
    border-top: solid thin $gray;
    top: 16px;
    transform: rotate(270deg);
  }

  &::after {
    content: "";
    display: block;
    height: 1px;
    margin-left: 11px;
    width: 14px;
    top: 21px;
    position: absolute;
    border-bottom: solid thin $gray;
    border-radius: 0;
  }
}

.ztree li span.button.bottom_docu{
  &::before {
    content: "";
    display: block;
    height: 100%;
    margin-left: 10px;
    width: 24px;
    position: absolute;
    border-left: solid thin $gray;
    top: -3px;
  }

  &::after {
    content: "";
    display: block;
    height: 1px;
    margin-left: 11px;
    width: 14px;
    top: 21px;
    position: absolute;
    border-bottom: solid thin $gray;
    border-radius: 0;
  }
}

.ztree li span.button.noline_docu {
  background: none;
}

.ztree li span.button.ico_open::before {
  content: "\f115";
  font-family: "Font Awesome 6 Pro";
  color: $primary;
}

.ztree li span.button.ico_close::before {
  content: "\f114";
  font-family: "Font Awesome 6 Pro";
  padding-top: 22px;
  padding-left: 4px;
  display: inline-block;
  color: $primary;
}

.ztree li span.button.ico_docu::before {
  content: "\f022";
  font-family: "Font Awesome 6 Pro";
  padding-top: 22px;
  padding-left: 4px;
  display: inline-block;
  color: $primary;
}

.ztree li span.button.edit {
  margin-left: 4px;
  margin-right: -1px;
  vertical-align: top;
  *vertical-align: middle;
  padding-top: 22px;
}

.ztree li span.button.edit::before {
  content: "\f044";
  font-family: "Font Awesome 6 Pro";
}

.ztree li span.button.remove {
  margin-left: 4px;
  margin-right: -1px;
  vertical-align: top;
  *vertical-align: middle;
  padding-top: 22px;
}

.ztree li span.button.remove::before {
  content: "\f1f8";
  font-family: "Font Awesome 6 Pro";
}

.ztree li span.button.add {
  margin-left: 4px;
  margin-right: -1px;
  vertical-align: top;
  *vertical-align: middle;
  padding-top: 10px;
}

.ztree li span.button.add::before {
  content: "\f067";
  font-family: "Font Awesome 6 Pro";
}

ul.tmpTargetzTree {
  background-color: #FFE6B0;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.ztree li ul.line {
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    height: calc(100% + 58px);
    margin-top: -50px;
    margin-left: -12px;
    border-left: solid thin $gray;
  }
}

span.tmpzTreeMove_arrow {
  width: 16px;
  height: 25px;
  display: inline-block;
  padding: 0;
  margin: 2px 0 0 1px;
  border: 0 none;
  position: absolute;
  background-color: transparent;
  background-attachment: scroll;
}

span.tmpzTreeMove_arrow::before {
  content: "\f04b";
  font-family: "Font Awesome 6 Pro";
  color: #ffff00;
}

ul.ztree.zTreeDragUL {
  z-index: 100;
  margin: 0;
  padding: 0;
  position: absolute;
  width: auto;
  height: 36px;
  overflow: hidden;
  background-color: #cfcfcf;
  border: 1px #ffff00 dotted;
  opacity: 0.8;
  filter: alpha(opacity=80);
  border-radius: 36px;

  .selectable-area {
    padding: 3px;
  }

  .node_name {
    padding: 0 6px;
  }
}

.ztreeMask {
  z-index: 10000;
  background-color: #cfcfcf;
  opacity: 0.0;
  filter: alpha(opacity=0);
  position: absolute;
}

span.button.non-answered::before,
.ztree li span.button.non-answered::before,
.ztree li span.button.non-answered_ico_open::before,
.ztree li span.button.non-answered_ico_close::before,
.ztree li span.button.non-answered_ico_docu::before {
  content: "\f111";
  font-size: 16px;
  font-family: "Font Awesome 6 Pro";
  color: $gray;
  font-weight: 200;
}

span.button.in-flight::before,
.ztree li span.button.in-flight::before,
.ztree li span.button.in-flight_ico_open::before,
.ztree li span.button.in-flight_ico_close::before,
.ztree li span.button.in-flight_ico_docu::before {
  content: "\f192";
  font-size: 16px;
  font-family: "Font Awesome 6 Pro";
  color: $warning;
  font-weight: 900;
}

span.button.answered::before,
.ztree li span.button.answered::before,
.ztree li span.button.answered_ico_open::before,
.ztree li span.button.answered_ico_close::before,
.ztree li span.button.answered_ico_docu::before {
  content: "\f058";
  font-size: 16px;
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
  color: $success;
}

.tree-container {
  .level0 {
    padding-left: 0;
    width:100%;
  }

  .tree-body {
    @include hidden-scrollbar();
    height: calc(100vh - 169px);
    min-height: calc(100vh - 169px);
    overflow-y: auto;

    &.edit-mode-gpt-suggestions {
      height: calc(100vh - 337px);
      min-height: calc(100vh - 337px);
    }
    &.edit-mode {
      height: calc(100vh - 336px);
      min-height: calc(100vh - 336px);

      &.two-rows {
        height: calc(100vh - 264px);
        min-height: calc(100vh - 264px);
      }
    }
    &.assign-decisions-mode {
      height: calc(100vh - 224px);
      min-height: calc(100vh - 224px);
    }

    &.assign-mode {
      height: calc(100vh - 217px);
      min-height: calc(100vh - 217px);
    }

    @media only screen and (max-width: 768px) {
      &.edit-mode, &.assign-decisions-mode {
        height: auto;
        min-height: auto;
      }
    }
  }
}

.footer-done-space {
  margin-bottom: 500px;
}

.ztree li a.tmpTargetNode_inner {
  background-color: $secondary;
  color: $dark;
  height: auto;
  border: none;
  opacity: 1;
  filter:alpha(opacity=80);
}

ul.tmpTargetzTree {
  background-color: $white;
  opacity: 1;
  filter:alpha(opacity=80)
}

.ztree li a.tmpTargetNode_prev {
  border-top: 2px $secondary solid;
  height: auto;
  border-radius: 0;
}

.ztree li a.tmpTargetNode_next {
  border-bottom: 2px $secondary solid;
  height: auto;
  border-radius: 0;
}

// Hide line to drag&drop driver above decision node
#decisionTree_1_a.tmpTargetNode_next,
#decisionTreeAssignments_1_a.tmpTargetNode_next,
#decisionTreePanel_1_a.tmpTargetNode_next,
#decisionSetTree_1_a.tmpTargetNode_next,
#decisionSetTreePanel_1_a.tmpTargetNode_next {
  border-bottom: none;
}

// Hide line to drag&drop driver as second root(second decision node)
#decisionTree_1_a.tmpTargetNode_prev,
#decisionTreeAssignments_1_a.tmpTargetNode_prev,
#decisionTreePanel_1_a.tmpTargetNode_prev,
#decisionSetTree_1_a.tmpTargetNode_prev,
#decisionSetTreePanel_1_a.tmpTargetNode_prev{
  border-top: none;
}

#setSectionsTree {
  #setSectionsTree_1_a {
    font-weight: 500;

    span {
      padding-left: 0;

      &.button {
        display: none;
      }
    }

    &.tmpTargetNode_prev,
    &.tmpTargetNode_next {
      border: none !important;
    }
  }

  &.ztree li span.button.root_open,
  &.ztree li span.button.root_close,
  &.ztree li span.button.roots_open,
  &.ztree li span.button.roots_close,
  &.ztree li span.button.center_open,
  &.ztree li span.button.center_close,
  &.ztree li span.button.bottom_open,
  &.ztree li span.button.bottom_close {
    cursor: none;
    pointer-events: none;
  }

  &.ztree li span.button.bottom_open::after,
  &.ztree li span.button.bottom_close::after,
  &.ztree li span.button.center_open::after,
  &.ztree li span.button.center_close::after {
    content: "";
    display: block;
    width: 17px;
    margin-left: 10px;
    position: absolute;
    height: 44px !important;
    border-left: solid thin $gray;
    border-bottom: solid thin $gray;
    bottom: 2px;
  }

  &.ztree li #setSectionsTree_2_switch::after{
    height: 29px !important;
  }

  & li span.button::after {
    .decision-order & {
      margin-left: 10px;
      width: 17px;
    }
  }

  & li span.button.bottom_open::after {
    border-left: solid thin $gray;
    height: 24px;
  }

  & li span.button:not(.non-answered, .in-flight, .answered)::before {
    content: none;
  }
}

#setSectionsTree_1_a {
  &.curSelectedNode {
    padding-top: 0px;
    background-color: white;
    color: $dark;
    height: unset;
    opacity: unset;
  }
}

.historical-decision-tree {
  #decisionTree_1_a,
  #decisionTreeAssignments_1_a,
  #decisionTree_1_switch,
  #decisionSetTree_1_a,
  #decisionSetTree_1_switch {
    display: none;
  }
}

.ztree li a.tmpTargetNode_inner.prevent-inner-drag {
  background-color: white;
  border: none;
}

.arrow-up-icon-block {
  position: absolute;
  bottom: -16px;
  margin-left: -3px;

  &.ml-expanded {
    margin-left: -15px;
  }
}

.poll-border-to-arrow {
  margin-top: 18px;
  &:before {
    border-left: 3px solid $light-gray;
    content: "";
    display: block;
    position: absolute;
    height: 14px;
    top: -14px;
    left: 16px;
    z-index: 0;
    pointer-events: none;
    cursor: none;
  }
}

.driver-block {
  min-width: 300px;
}

.border-to-arrow, .vertical-tree {
  .border-to-arrow {
    &:before {
      @include common-before(0, calc(100% + 12px));
    }

    &:last-child:before {
      @include common-last-child-before();
    }
  }

  .driver-block.border-short {
    &:before {
      height: 40px;
    }
  }
  .driver-block {
    &:before {
      @include common-before(-20px, calc(100% + 12px));
    }

    &:last-child:before {
      @include common-last-child-before();
    }

    &:after {
      @include driver-block-after;
    }
  }
}

.ml-border-1 {
  &:before{
    margin-left: 16px;
  }
}

// Loop to generate classes with different margins
// Adjust the range as needed
@for $i from 2 through 100 {
  .ml-border-#{$i} {
    &:before{
      margin-left: calc(#{$i - 1} * 36px + 16px);
    }
  }
}
