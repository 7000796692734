.sliding-panel-container,
.max-panel-container {
  iframe {
    height: calc(100vh - 62px);
  }
}

.max-panel-container {
  background-color: $light-gray;
  left: 0;
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 101;

  .tableau-content {
    width: 100%;
    padding: 0 12px;
  }
}

.iframe-view {
  width: 100%;
  min-height: 500px;
  background-color: $white;

  .minimize {
    height: calc(100vh - 160px);
  }

  .maximize {
    height: calc(100vh - 62px);
  }
}

.tableau-iframe {
  width: inherit;
  #viz-client-container {
    width: auto !important;
  }
}
