@import 'mixins';

.report-decisions-pagination {
  @include pagination();
}

.report-properties {
  .d-table-row:last-child {
    .d-table-cell {
      padding-bottom : 0 !important;
    }
  }
}

.rating-slider-container {
  .rating-slider:not(:first-child) {
    margin-top: 24px;
  }
}