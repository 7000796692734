.radio-label {
  width: 100%;
  margin-bottom: 1em;
  text-align: center;
}

.radio-pillbox::before {
  position: relative;
  top: 25px;
  background: -webkit-linear-gradient(
      100deg,
      #F0584D 0%,
      #FBB03F 30%,
      #FFD940 50%,
      #9CD673 70%,
      #39D4A6 100%
  );
  background: -moz-linear-gradient(
      100deg,
      #F0584D 0%,
      #FBB03F 30%,
      #FFD940 50%,
      #9CD673 70%,
      #39D4A6 100%
  );
  background: -o-linear-gradient(
      100deg,
      #F0584D 0%,
      #FBB03F 30%,
      #FFD940 50%,
      #9CD673 70%,
      #39D4A6 100%
  );
  background: -ms-linear-gradient(
      100deg,
      #F0584D 0%,
      #FBB03F 30%,
      #FFD940 50%,
      #9CD673 70%,
      #39D4A6 100%
  );
  background: linear-gradient(
      100deg,
      #F0584D 0%,
      #FBB03F 30%,
      #FFD940 50%,
      #9CD673 70%,
      #39D4A6 100%
  );
  content: '';
  display: block;
  height: 1px;
  width: 96%;
}

.radio-pillbox {
  height: 5.5rem;
  border: 0;

  input {
    &:focus {
      outline: 0;
    }
  }

  .radio-container {
    div {
      label {
        width: 48px;
      }
    }
    .rating-circle {
      width: 48px;
      height: 48px;
      transition: all 0.2s ease;
      &::after {
        font-size: 0.75rem;
        display: block;
        pointer-events: none;
        color: $muted;
        font-weight: 500;
        @media (max-width: 450px) {
          display: none;
        }
      }
      &.active, &:hover {
        label {
          color: $dark;
        }
        &::after {
          transition: all 0.2s ease;
          color: $dark;
          display: block;
        }
      }
      &.non-active {
        background-color: #F8F9FA !important;
        border-color: #F8F9FA;
        label {
          color: $gray;
        }
        &::after {
          color: $gray;
          @media (max-width: 450px) {
            display: none;
          }
        }
      }
    }
    .not-at-all-confident {
      &::after {
        content: 'Not at all confident';
        width: 60px;
        text-align: left;
      }
    }
    .not-so-confident {
      &::after {
        content: 'Slightly confident';
        width: 72px;
        position: relative;
        left: -12px;
        text-align: center;
      }
    }
    .somewhat-confident {
      &::after {
        content: 'Moderately confident';
        width: 72px;
        position: relative;
        left: -12px;
        text-align: center;
      }
    }
    .very-confident {
      &::after {
        content: 'Very confident';
        width: 72px;
        position: relative;
        left: -12px;
        text-align: center;
      }
    }
    .extremely-confident {
      &::after {
        content: 'Extremely confident';
        position: absolute;
        right:0;
        width: 72px;
        text-align: right;
      }
    }
  }
}

.driver-border {
  margin-left: -10px;
  min-width: 300px;
}

.driver-confidence-bubble {
  height: 2.5rem;
  border: 0;

  .radio-container {
    div {
      label {
        width: 36px;
      }
    }
    .rating-circle {
      width: 36px;
      height: 36px;
      label {
        color: $dark;
        font-size: 0.875rem;
      }
      &::after {
        color: $dark;
        position: absolute;
        left: 48px;
        line-height: 36px;
        width: 200px;
      }
    }
  }
}

.radio-container {
  div {
    position: relative;
    background-color: white;
    border-radius: 50%;
    input {
      -webkit-appearance: inherit;
      width: 100%;
      height: 100%;
    }
    label {
      position: absolute;
      top: 50%; /* top plus line-height centers text */
      left: 0;
      line-height: 0;
      width: 22px;
      text-align: center;
      color: $primary;
      font-size: 1.125rem;
      font-weight: 700;
    }
  }
  .square-24.rating-circle {
    label {
      color: $dark;
      font-size: 0.875rem;
    }
    &::after {
      color: $dark;
      position: absolute;
      left: 48px;
      line-height: 36px;
      width: 200px;
    }
  }
  .not-at-all-confident {
    border: 1px solid #F0584D;
    &.active, &:hover {
      background-color: #F0584D;
    }
    &::after {
      content: 'Not at all confident';
    }
  }
  .not-so-confident {
    border: 1px solid #FBB03F;
    &.active, &:hover {
      background-color: #FBB03F;
    }
    &::after {
      content: 'Slightly confident';
    }
  }
  .somewhat-confident {
    border: 1px solid #FFD940;
    &.active, &:hover {
      background-color: #FFD940;
    }
    &::after {
      content: 'Moderately confident';
    }
  }
  .very-confident {
    border: 1px solid #9CD673;
    &.active, &:hover {
      background-color: #9CD673;
    }
    &::after {
      content: 'Very confident';
    }
  }
  .extremely-confident {
    border: 1px solid #39D4A6;
    &.active, &:hover {
      background-color: #39D4A6;
    }
    &::after {
      content: 'Extremely confident';
    }
  }
}

.driver-type-pill {
  display: inline-block;
  padding: 0 12px !important;
  text: white;
  font-size: 14px;
  line-height: 24px;
  background-color: $gray;
}

.comments {
  .rounded-circle {
    cursor: default;
  }
  .current-user-message {
    cursor: pointer;
  }
}