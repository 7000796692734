.ag-cell-highlight {
    background-color: white !important;

}
.ag-cell-data-changed-animation {
    background-color: white !important;
}
.tab-content {
  height: calc(100vh - 72px);

  &.decomposition-tab-spacing {
    padding: 0 24px 24px;
    height: calc(100vh + 46px);
  }
}

.ag-theme-quartz {
  --ag-header-background-color: #F2F6FA !important;
  --ag-icon-font-color-tree-open: #2487BF !important;
  --ag-icon-font-color-tree-closed: #2487BF !important;
  --ag-row-hover-color: #f8f9fa !important;
  &.ag-theme-quartz-no-highlights {
    --ag-value-change-value-highlight-background-color: transparent !important;
    .ag-cell-highlight {
      background-color: transparent !important;
      color: inherit !important;
    }
  }
}

.ag-quartz-styles {
  height: calc(100vh - 192px);
}

.forecast-simulator-header {
  background: $body-background;

  .nav-tabs {
    margin-bottom: -11px;
    align-self: self-end;
    border-bottom: $white;
    border-color: $white;

    .nav-item {
      text-align: center;
      border-bottom: $white;

      .nav-link {
        background: $light-gray;
        height: 48px;


        &.active {
          background: $white;
          border-bottom: 0px $white;
          border-left: 1px solid $white;
          border-right: 1px solid $white;
          border-top: 1px solid $white;
        }

        &:hover {
          border-bottom: none;
          border-color: $body-background;
        }
      }
    }
  }

  .search-decisions {
    min-width: 363px;
  }
  .dropdown-menu-scroll {
    max-height: 200px;
    overflow-y: auto;
  }
}

.decision-tree-header {
  &.actions-row {
    position: inherit;
  }
}

.forecast-chart-container {
  height: calc(100vh - 306px);
}

.chart-col, .totals-col {
  width: 100%;
}

@media only screen and (min-width: 992px) {
  .forecast-chart-container {
    height: calc(100vh - 210px);
  }

  .chart-col {
    width: calc(100% - 200px);
  }

  .totals-col {
    width: 200px;
  }
}

.scenario-container {
  max-width: calc((100% - 538px)/2);

  .d-inline-flex.dropdown {
    width: 100%;

    .dropdown-toggle {
      text-align: start;
      max-width: 100%;
      position: relative;

      &:after {
        position: absolute;
        top: 0;
        right: -8px;
      }
    }
  }
}

.ag-row-group-indent-1 {
  --ag-row-group-indent-size: 50px !important;
}

@media only screen and (min-width: 1024px) {
  .scenario-container {
    max-width: calc((100% - 710px)/2);
  }

  .forecast-simulator-header {
    .nav-tabs {
      width: 477px;
      min-width: 477px;

      .nav-item {
        width: 155px;
      }
    }
  }
}
