@import 'colors';

ul.tree {
  list-style: none;
  padding: 0;
  .rounded {
    border-radius: 0.75rem !important;
    line-height: initial;
  }
  li {
    border-left:1px solid #2487BF;
    line-height: 25px;
    margin: 0;
    padding: 3px 0 0 7px;
    &:last-child {
      border-left:none;
    }
    &:not(.is-decision):before {
      border-bottom:1px solid #2487BF;
      color: white;
      content: "";
      display: inline-block;
      height: 2em;
      left: -7px;
      position: relative;
      top: -0.3em;
      width: 12px;
    }
  }
  ul {
    list-style: none;
    margin: 0 0 0 10px;
    padding: 0;
    li:not(.is-decision):last-child:before {
      border-left:1px solid #2487BF;
    }
  }
}
.dsight-datasource {
  border-radius: 4px;
  font-size: 0.75rem;
}
.MetricsSelect__option--is-selected {
  background-color: $light-gray;
}
