.jodit-react-container {
  color: $dark;
  font-size: 14px !important;
  font-weight: normal !important;
  line-height: 21px !important;

  .jodit-container-default, .jodit_theme_default {
    min-height: 128px !important;

    .jodit-workplace, .jodit-editor-container {
      min-height: 87px !important;

      button, svg, i {
        display: none;
      }
    }

    iframe {
      display: none;
    }

    p {
      line-height: 21px;
    }

    b, strong {
      font-weight: bold !important;

      h1, h2, h3, h4, h5 {
        font-weight: bold !important;
      }
    }

    h1, h2, h3, h4, h5 {
      color: $dark;
      font-size: 14px !important;
      font-weight: normal !important;
      line-height: normal !important;
      margin: 0 !important;
    }
  }

  .jodit-placeholder {
    color: $gray;
    z-index: 0;
    margin-left: 0 !important;
  }

  .jodit-container {
    background-color: $white !important;
    border: 1px solid $border-color !important;
    border-radius: $btn-border-radius !important;
  }
  .jodit-toolbar__box, .jodit-toolbar__box:not(:empty), .jodit-toolbar__box:not(:empty):not(:empty) {
    background-color: $white;
    border-top-left-radius: $btn-border-radius;
    border-top-right-radius: $btn-border-radius;
    border-bottom-color: $border-color;
  }
  .jodit-container:not(.jodit_inline) .jodit-workplace {
    border-bottom-left-radius: $btn-border-radius;
    border-bottom-right-radius: $btn-border-radius;
  }
}

.jodit-editor-container {
  padding: 12px 15px !important;

  p, ol, ul {
    margin-bottom: 0;
  }

  img {
    display: none !important;
  }

  table {
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    td {
      padding: 0.4em;
      height: 33px;
    }
  }
}

.jodit-wysiwyg table tr td {
  border: 1px solid $border-color !important;
  min-height: 12px;
}

.jodit-toolbar-button {
  .ql-stroke {
    fill: none;
    stroke: #444;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
  }

  .jodit-icon {
    height: 18px !important;
    width: 18px !important;
  }
}

.jodit-toolbar__box {
  .ql-fill {
    fill: #444;
  }

  .ql-thin-1 {
    stroke-width: 1;
  }

  .ql-thin-0 {
    stroke-width: 0;
  }

  .ql-even {
    fill-rule: evenodd;
  }

  .jodit-toolbar-button__trigger {
    display: none;
  }
}

.jodit-popup-container {
  .jodit-ui-button_Border, .jodit-ui-group__valign, .jodit-ui-group__align, .jodit-ui-group__merge,
  .jodit-ui-group__brush, .jodit-ui-group__splitv {
    display: none !important;
  }
}
