.followup-modal {
  .radio-pillbox::before {
    background: $primary;
    position: relative;
    top: 25px;
  }
  .radio-pillbox {
    height: 5.5rem;
    border:0;
    input {
      &:focus {
        outline: 0;
      }
    }

    .radio-container {
      .rating-circle {
        border: 1px solid $primary;
        &::after {
          font-size: 12px;
          display: block;
          text-align: center;
          color: $gray;
          font-weight: 600;
          pointer-events: none;
        }
        &.active, &:hover {
          background-color: $primary;
          label {
            color: $white;
          }
          &::after {
            transition: all 0.2s ease;
            color: $dark;
          }
        }
        &.non-active {
          background-color: $light;
          border-color: $light;
          label {
            color: $gray;
          }
          &::after {
            color: $gray;
            @media (max-width: 450px) {
              display: none;
            }
          }
        }
      }
      .mush-worse::after {
        content: 'Much worse';
      }
      .worse::after {
        content: 'Worse';
      }
      .on-track::after {
        content: 'As expected';
        width:72px;
        position: relative;
        left: -12px;
      }
      .better::after {
        content: 'Better';
      }
      .mush-better::after {
        content: 'Much better';
      }
    }
  }
  .active-button {
    background-color: shade-color($primary, 10%);
  }
}
