.chat {
  .card {
    .card-body, .chat-body {
      height: calc(100vh - 268px);
      @media only screen and (max-width: 767px) {
        height: calc(100% - 268px);
      }
    }
    .chat-body {
      overflow-y: auto;
      &.full-height-chat {
        height: calc(100vh - 159px);
        @media only screen and (max-width: 767px) {
          height: calc(100% - 159px);
        }
      }
    }
  }
  .card-footer {
    background-color: white;
    border: none;
  }

  .message-body {
    background-color: $light;
    &.highlight {
      background-color: $primary;
    }
    -webkit-transition: background-color 1000ms linear;
    -moz-transition: background-color 1000ms linear;
    -o-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
  }
  .header-row {
    color: $gray;
  }
  .current-user-message {
    .message-body {
      background-color: $secondary;
      &.highlight {
        background-color: $primary;
      }
    }
  }
  &.comments {
    .header-row {
      color: $dark-gray;
    }
    .message-body {
      .message-text {
        word-break: break-all;
        a {
          color: $blue;
        }
      }
    }
    .current-user-message {
      position: relative;
      .message-body {
        background-color: $secondary;
        color: $dark;
        &.highlight {
          background-color: $primary;
        }
      }
      .user-comment-actions {
        position: absolute;
        right: 12px;
        top: 12px;
      }
    }
  }
}
.message-input-max-width {
  max-width: calc(100% - 48px);
}
.message-input-max-height {
  height: 48px;
}
