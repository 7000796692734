.swal2-container {
  &.fade.in:not(.show) {
    opacity: 1;
  }
  h2 {
    font-size: 1.5rem !important;
    font-weight: bold;
    line-height: normal;
    color: $dark;
  }
  .swal2-modal {
    font-family: inherit;
  }
  .swal2-content {
    font-weight: normal;
    font-size: 1.125rem;
    color: $dark;
    margin-top: 0.875rem;
  }
  a {
    color: $primary;
  }
  button {
    padding: 10px 32px;
    &.swal2-confirm {
      &:focus {
        background-color: $primary !important;
        &:hover { background-color: shade-color($primary, 10%) !important; }
      }
    }
    .swal2-cancel {}
  }
  .swal2-icon {
    .placeholder {
      background-color: $white;
      cursor: auto;
      border: 4px solid rgba(165, 220, 134, 1);
      opacity: 0.2;
    }
  }

  .button-w-50 {
    padding: 0;
    max-width: calc(50% - 6px);
    width: 50%;
  }

  @media only screen and (max-width: 768px) {
    button {
      padding: 0px 32px;
    }
  }

  @media only screen and (max-width: 400px) {
    button {
      width: 100px;
      padding: 0px 15px;
    }
  }
}
