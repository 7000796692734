.users-container {
  max-width: 500px;
}
.user-tile {
  border-radius: $btn-border-radius;
}
.checkbox-line {
  line-height: 0.8rem;
}

.users-filter {
  max-width: 135px;
}

.row-actions {
  min-width: max-content;
}