@font-face {
  font-family: 'Montserrat';
  src: url('../../../../assets/fonts/Montserrat/Montserrat-Light.woff2') format('woff2'),
  url('../../../../assets/fonts/Montserrat/Montserrat-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../../../../assets/fonts/Montserrat/Montserrat-LightItalic.woff2') format('woff2'),
  url('../../../../assets/fonts/Montserrat/Montserrat-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../../../assets/fonts/Montserrat/Montserrat-Regular.woff2') format('woff2'),
  url('../../../../assets/fonts/Montserrat/Montserrat-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../../../../assets/fonts/Montserrat/Montserrat-Italic.woff2') format('woff2'),
  url('../../../../assets/fonts/Montserrat/Montserrat-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../../../assets/fonts/Montserrat/Montserrat-Medium.woff2') format('woff2'),
  url('../../../../assets/fonts/Montserrat/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../../../../assets/fonts/Montserrat/Montserrat-MediumItalic.woff2') format('woff2'),
  url('../../../../assets/fonts/Montserrat/Montserrat-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}