.rate-support-modal {
  .radio-pillbox {
    .radio-container {
      .not-at-all-confident {
        &::after {
          content: 'Not at all likely';
          color: $dark;
          font-weight: normal;
          @media (max-width: 450px) {
            display: inherit;
          }
        }
      }
      .not-so-confident {
        &::after {
          content: '';
        }
      }
      .somewhat-confident {
        &::after {
          content: '';
        }
      }
      .very-confident {
        &::after {
          content: '';
        }
      }
      .extremely-confident {
        &::after {
          content: 'Extremely likely';
          color: $dark;
          font-weight: normal;
          @media (max-width: 450px) {
            display: inherit;
          }
        }
      }
    }
  }
}

.support-details-modal {
  .radio-pillbox {
    height: auto;
    &:last-child {
      margin-bottom: 0 !important;
    }
    &::before {
      background: none;
      content: none;
    }
    .radio-container {
      .rating-circle {
        height: 36px;
        width: 36px;
        &::after { content: ''; }
        > span {
          position: absolute;
          top: 50%;
          left: 0;
          line-height: 0;
          width: 36px;
          text-align: center;
          color: $dark;
          font-weight: 700;
        }
      }
    }
    .support-header {
      max-width: 60px;
    }
  }
}
.support-details {
  .user-explanation {
    border-bottom-left-radius: 0px !important;
  }
}
