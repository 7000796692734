.sign_in {
  max-width: 500px;
}

.has-icon {
  .form-control {
    padding-right: 3.25rem;
  }
  .form-control-icon {
    position: absolute;
    top: 13px;
    right: 25px;
    z-index: 2;
    display: block;
    .fa-2x {
      font-size: 1.525em;
    }
  }
}

.or-with-line {
  &::before {
    content: "";
    display: block;
    border-top: solid 1px $muted;
    width: 100%;
    height: 1px;
    position: relative;
    top: 9px;
    z-index: 1;
  }
  color: $gray;
  font-weight: $headings-font-weight !important;
  margin: 0;
  span {
    background: $white;
    padding: 0 6px;
    position: relative;
    z-index: 5;
  }
}
.btn-social {
  color: $white;
  border-color:rgba(0,0,0,0.2);
}
.btn-google {
  background-color: $google-btn-color;
  &:active,
  &:focus,
  &:hover {
    background-color: shade-color($google-btn-color, 7%);
  }
  &.disabled,
  &:disabled,
  &[disabled] {
    background-color: tint-color($google-btn-color, 7%);
  }
}
.btn-microsoft {
  background-color: $microsoft-btn-color;
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: shade-color($microsoft-btn-color, 7%);
  }
  &.disabled,
  &:disabled,
  &[disabled] {
    background-color: tint-color($microsoft-btn-color, 7%);
  }
}
//slack custom styles
.btn-slack {
  background-color: $mediumseagreen;
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: #29a06f;
  }
  &.disabled,
  &:disabled,
  &[disabled] {
    background-color: #8dd6b6;
  }
}
