.decision-tree-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: $white;
  border-bottom: 1px solid $light;
  z-index: 200;
  height: 72px;
  .navbar{
    height: inherit;
    &.sub-nav-bar {
      height: auto;
    }
  }
  .arrow-left {
    height: 48px;
    width: 48px;
    border-radius: 50%
  }
  .decision-description {
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .decision-context {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    min-width: 290px;
  }
  .progress {
    height: 3px;
    margin-top: -3px;
    background-color: $white;
    border-radius: 0;
    border-bottom: 1px solid $light;
  }
  label.due-date-datepicker-label {
    position: relative;
    margin-left: -130px;
    color: $primary;
    font-weight: 500;
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
  .dropdown-toggle {
    color: $primary;
    &, &:active, &:focus, &:hover {
      border: none;
      color: inherit;
      background-color: transparent !important;
    }
  }
  .dropdown-toggle-header {
    color: $primary;
    &, &:active, &:focus, &:hover {
      border: none;
      color: inherit;
      background-color: #D0EEFF !important;
    }
  }
  &.homepage-header {
    //navigation underline styles
    .d-lg-flex {
      a {
        text-decoration: none;
      }
      .nav-link, .settings-btn {
        padding: 0.75rem 0 1.9rem 0;
        margin: 0 0.75rem;
        line-height: 1.5rem;
        color: $gray;
        &:hover {
         color: $dark;
        }
      }
      .nav-link.active {
        color: $dark;
        border-bottom: 6px solid $primary;
        .settings-btn {
          color: inherit;
        }
      }
    }
    .sub-nav-bar {
      .d-lg-flex {
        .nav-link {
          padding: 0.75rem 0 0.9rem 0;
          line-height: 0.5rem;
        }
        .nav-link.active {
          border-bottom: none;
        }
      }
    }
  }
}

.header-title, .header-message, .header-done-button {
  flex-direction: column !important;
}

.header-title {
  color: $dark;
  font-weight: 600;
}

.header-message, .header-done-button {
  margin: auto 0;
}

.header-message {
  color: mediumseagreen;
  font-weight: 400;
  padding-top: 0.4%;
  width: 30%;
}

.header-message .fa-check-circle {
  padding-right: 5px;
}

.hidden {
  visibility: hidden;
}

.header-done-button {
  height: 50px;
  width: 150px;
}

.header-done-btn {
  width: 100px;
}

@media only screen and (max-width: 991px) {
  .h3.decision-description {
    font-size: $h5-font-size;
  }
}
