.vertical-tree {
  max-width: 646px;

  .driver-data-sources {
    display: block;
    position: relative;
    max-height: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 1rem;

    &::before {
      position: absolute;
      content: "...";
      color: $dark;
      background-color: $white;
      bottom: -4px;
      right: 4px;
    }

    &::after {
      content: "";
      position: absolute;
      right: 0px;
      width: 16px;
      height: 2rem;
      background-color: $white;
    }

    .source-icon {
      max-width: 60px;
      height: 17px;
    }

    .file-icon {
      font-size: 17px;
    }

    .fa-file-zipper, .fa-file-powerpoint
    {
      color: $orange;
    }

    .fa-link {
      max-width: 27px;

    }

    .fa-link, .fa-file-word, .fa-file-alt {
      color: $primary;
    }

    .fa-file-pdf {
      color: $danger;
    }

    .fa-file-video, .fa-file-audio, .fa-file-image {
      color: $gray;
    }

    .fa-file-excel, .text-success {
      color: $success;
    }

    .fa-file-csv, .text-success {
      color: $success;
    }

    .fa-files {
      color: $purple;
    }
  }

  .vertical-tree-elements {
    width: -webkit-fill-available;
    width: -moz-available;
  }
}

@keyframes slideDown {
  0% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes slideUp {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes growMobile {
  from {
    width: 300px;
  }
  to {
    width: calc(50vw - 48px);
  }
}

@keyframes growRegular {
  from {
    width: 646px;
  }
  to {
    width: calc(50vw - 168px);
  }
}

@keyframes slideRightDown {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes slideRightUp {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

.toggle-sub-drivers {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  top: 18px;
  left: -30px;

  .up:not(.first-load) {
    transform: rotate(0deg);
    animation: 0.3s ease-in 0s 1 slideRightDown;
  }

  .down {
    transform: rotate(180deg);
    animation: 0.3s ease-in 0s 1 slideRightUp;
  }
}

.expand-driver {
  .down:not(.first-load) {
    transform: rotate(0deg);
    animation: 0.3s ease-in 0s 1 slideDown;
  }

  .up {
    transform: rotate(-180deg);
    animation: 0.3s ease-in 0s 1 slideUp;
  }
}

.vertical-tree-grow {
  margin: auto;
}

.vertical-assigned-drivers-tree-grow {
  margin: auto;
}

.vertical-tree-container {
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .vertical-tree-container {
    overflow: auto;
  }

  .vertical-tree {
    min-width: fit-content;

    & > *{
      max-width: 624px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vertical-tree-grow {
    width: calc(50vw - 48px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vertical-assigned-drivers-tree-grow {
    width: calc(50vw - 48px);
  }
}

@media only screen and (min-width: 992px) {
  .vertical-tree-grow {
    width: calc(50vw - 168px);
    max-width: unset;
  }
}

@media only screen and (min-width: 992px) {
  .vertical-assigned-drivers-tree-grow {
    width: 55vw;
    max-width: unset;
  }
}

@media (min-width: 1648px){
  .vertical-tree {
    max-width: calc(50vw - 168px);
  }
}
