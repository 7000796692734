.body {
  .sliding-panel-container{
    z-index: 100 !important;
    width: 100%;
    background-color: transparent !important;
    pointer-events: none;
  }

  .forecast-sliding-panel {
    .sliding-panel-container{
      z-index: 201 !important;

      .panel-content {
        top: 0;
      }
    }
  }


  .left-side-panel-position {
    position: fixed !important;

    &.home-page {
      left: 0;

      @media only screen and (min-width: 992px) {
        left: 269px;
      }
    }

    @media only screen and (min-width:992px) {
      &.tree-page {
        left: 240px;
      }
    }
  }
  .sidebar-panel-wrapper {
    &.simulator-settings-panel-wrapper, &.large-scale-panel-wrapper {
      min-width: 444px;
    }
  }

  .sidebar-panel-wrapper,
  .right-sidebar-panel-wrapper {
    background-color: $white;
    max-width: calc(50vw - 120px);
    min-width: 367px;
    pointer-events: auto;
    border-left: 1px solid $light;
    border-right: 1px solid $light;

    @media only screen and (max-width: 767px) {
      min-width: 100%;
    }

    @media only screen and (max-width:991px) {
      max-width: 50vw;
    }

    @media only screen and (min-width:1840px) {
      max-width: 800px;
    }

    .panel-content {
      height:calc(100% - 72px);
      position:relative;
      top: 72px;
      overflow: auto;
    }
  }

  .sidebar-panel-wrapper {
    margin-left: auto;
  }

  .center-sidebar-panel-wrapper {
    background-color: $white;
    border-left: 1px solid $light;
    border-radius: 12px;
    border-right: 1px solid $light;
    left: 33vw;
    margin-top: 110px;
    max-width: 50vw;
    min-width: 367px;
    pointer-events: auto;

    @media only screen and (max-width: 990px) {
      left: 15%;
      min-width: 70%;
    }

    @media only screen and (max-width: 768px) {
      left: 2%;
      min-width: 96%;
    }

    .panel-content {
      height: calc(100% - 72px);
      overflow: auto;
      position: relative;
    }
  }

  .right-sidebar-panel-wrapper {
    right: 0;
    position: absolute !important;

    &.panel-content {
      height: calc(100vh - 72px) !important;
    }
  }

  .center-container-block,
  .right-sidebar-panel-wrapper {
    .support-widget-col {
      width: 189px;
      height: 92px;

      .participants-support-label {
        font-family: 'Montserrat';
        line-height: 1.2rem;
        text-align: center;

        .label-value {
          font-size: $h1-font-size;
        }

        .text-muted {
          font-size: $h5-font-size;
        }

        .label-description {
          font-size: 0.375rem;
        }
      }
    }

    .user-supports {
      height: 50px;

      .rounded-circle {
        height: 36px;
        width: 36px;
        border: thin solid white;

        &:not(:first-child) {
          margin-left: -5px;
        }
      }
    }
  }

  .help-side-panels {
    .sidebar-panel-wrapper {
      max-width: 315px;
      min-width: 315px;

      p {
        line-height: 1.066878rem;
      }
    }
  }

  .discussion-side-panels {
    .sliding-panel-container{
      bottom: 0;
      top: auto;
      max-height: 520px;
    }
  }

  .sidebar-choice-width {
    width: 100%;
    overflow-wrap: break-word;
  }
}

.icon-thumbs-up-stack {
  .fa-stack-2x {
    height: 34px;
    width: 34px;
    margin-left: 0;
    margin-bottom: -2px;
  }
}

.dsight-datasource-block {
  color: $primary !important;
}

.fa-layers-square {
  padding: 8px 10px;
}

.mobile-extra-height {
  height: 35vh;
}

.side-panel {
  position: -webkit-sticky;
  position: sticky;
  background-color: $white;
  z-index: 2;

  &.header {
    top: 0;
  }

  &.bottom{
    height: 96px;
    bottom: 0;
    margin-top: auto;
  }
}

#sidebar-navigation {
  min-height: calc(100vh - 72px);
  z-index: 101;

  @media only screen and (min-height: 700px) {
    .sidebar-top {
      position:fixed;
    }

    .sidebar-bottom {
      position: fixed;
      bottom: 0;
    }
  }

  &.tree-sidebar {
    min-width: 240px;

    .sidebar-top,
    .sidebar-bottom {
      width: 240px;
    }
  }

  &.home-sidebar {
    min-width: 270px;

    .sidebar-top,
    .sidebar-bottom {
      width: 270px;
    }
  }

  ul {
    li {
      a,
      .side-row {
        display: flex;
        align-items: center;
        font-size: $h5-font-size;
        border-radius: $border-radius;
        color: $dark;
        height: 3rem;

        .icon {
          width: 3rem;
        }

        &:hover,
        .settings-menu:hover {
          text-decoration: none;

          .icon {
            color: $primary;
          }
        }
      }

      &.active {
        > a,
        .side-row {
          background: $white;
          color: $primary;

          .tab-title {
            color: $dark;
          }
        }
      }
    }

    .icon.active {
      color: $primary;
    }

    &.list-settings {
      li a {
        color: $dark;
        padding-left: (2*$spacer);
      }
    }
  }
}

.numbered-square {
  border: 1px solid $gray-border;
  border-radius: $btn-border-radius;
  height: 48px;
  outline: none;
  padding: 12px 0;
  text-align: center;
  width: 51px;

  &::-webkit-input-placeholder {
    color: $gray;
  }
}

.poll-card-slider {
  .rc-slider {
    padding: 0;
    height: 12px;
  }
}

.rate-slider {
  margin: 6px 0 0 auto;
  width: calc(100% - 60px);

  .rc-slider-dot {
    display: none;
  }

  .rc-slider-handle-dragging {
    box-shadow: none !important;
    transform: unset !important;
  }

  .rc-slider-mark {
    .rc-slider-mark-text {
      max-width: 50%;
      line-height: 1;
      word-break: break-word;
      transform: unset !important;
    }

    .rc-slider-mark-text:first-child {
      left: 0% !important;
      padding-right: 6px;
      text-align: left;
    }

    .rc-slider-mark-text:last-child {
      left: unset !important;
      right: 0% !important;
      padding-left: 6px;
      text-align: right;
    }
  }
}

.popover-body {
  p {
    margin-bottom: 12px;
  }
}

.decision-sequence-pills {
  .nav-item {
    @media only screen and (max-width:1140px) {
      min-width: 100%;
    }
  }
}

.maximize-icon {
  @media only screen and (max-width:767px) {
    display: none;
  }
}

.forecast-sidebar {
  z-index: 200;
  .sidebar-panel-wrapper {
    .panel-content {
      top: 0;
    }
  }
}

.flex-column, .list-group {
  .edit-source-spacing {
    margin-bottom: 12px;

    &:first-child {
      margin: 12px 0;
    }
  }
}
