@import "colors";
@import "variables";
@import "fonts";
@import "~bootstrap/scss/bootstrap";
@import "datepicker";
@import "mixins";
@import "header";
@import "footer";
@import "sidebar";
@import "dsights";
@import "accordion_tree";
@import "tree";
@import "confirmations";
@import "driver";
@import "discussion";
@import "buttons";
@import "followup";
@import "user_support";
@import "home";
@import "users";
@import "groups";
@import "profile";
@import "login";
@import "filters";
@import "pdf";
@import "quill";
@import "decision_learning";
@import "tables";
@import "iframe";
@import "report_decisions";
@import "forecast_simulator";
@import "jodit";

html {
  font-size: 16px;
}

body, html {
  background: $body-background;
}

body,
.popover {
  font-family: 'Montserrat', 'omnes-pro', 'Open Sans', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: $font-size-base;
  color: $dark;
}

.h-0 {
  height: 0;
}

.h-inherit {
  height: inherit;
}

h1, .h1 {
  color: $header;
  font-size: $h1-font-size !important;
  line-height: 2.25rem !important;
}

h2, .h2 {
  color: $header;
  font-size: $h2-font-size !important;
  font-weight: normal !important;
  line-height: 2.25rem !important;
}

h3, .h3 {
  font-size: $h3-font-size !important;
  line-height: 1.5rem !important;
}

h4, .h4 {
  font-size: $h4-font-size !important;
  font-weight: normal !important;
  line-height: 1.5rem !important;
}

h5, .h5 {
  font-size: $h5-font-size !important;
  line-height: 1.5rem !important;
  margin-bottom: 0 !important;
}

a {
  text-decoration: none !important;
}

.fs-6 {
  font-size: $h6-font-size;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

//.nav-link {
//  display: block;
//  padding: 0.5rem 1rem;
//  color: $darken-btn-secondary-text;
//  text-decoration: none;
//  transition: color 0s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
//}

span.nav-link{
  &:focus, &:active {
    color: $white !important;
  }
}

small, .small {
  font-size: 80%;
  font-weight: 400;
}

.close {
  float: right;
  line-height: 1;
}

.lh-normal {
  line-height: normal;
}

.lh-checkbox {
  line-height: 10px;
}

.tooltip-top {
  bottom: 4px !important;
}

.w-max-content {
  width: max-content !important;
}

.width-100 {
  width: 100px;
}

.width-150 {
  width: 150px;
}

.h-36 {
  height: 36px;
}

.pointer, .pointer * {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.data-source-row {
  &:not(:last-child) {
    margin-bottom: 0.75rem !important;
  }

  a {
    text-decoration: none;
  }

  .width-cut {
    max-width: calc(100% - 36px)
  }
}

.source-info {
  line-height: 1;
}

.source-description {
  min-width: 0;

  &.source-description-width {
    position: absolute;
    left: 40px;
    top: -5px;
    width: calc(100% - 12px);
    padding-right: 120px;

    .form-control {
      height: calc(1.5em + 1.5625rem);
    }
  }
}

.modal-content {
  border-radius: 10px;
}

.modal-body,
.modal-header,
.modal-footer {
  padding: $spacer;
}

.modal-summary-details-block {
  .modal-summary-details-section > *{
    margin-bottom: 0;
  }
}

.on-modal {
  z-index: 1060 !important;
}

.alerts {
  z-index: 2000;
  position: absolute;
  top: $spacer;
  left: 0;
  right: 0;
  margin: auto;
  width: auto;
  max-width: 500px;
}

//For the circle numbers, currently used in the Results display
.wb-badge {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  text-align: center;
  line-height: 1.5rem;
  font-weight: bold;
  margin-left: 0.375rem;
}

.collaborator_img,
.user_image {
  img {
    width: 48px;
    height: 48px;
  }
}

.thumbnail-container {
  display: flex;
  background-color: $light;
  border-radius: 0.4rem;
  width: 37px;
  height: 37px;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;

  &.fa-0 {
    font-size: 0;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.sm-avatar {
  width: 36px !important;
  height: 36px !important;
}
.md-avatar {
  width: 48px !important;
  height: 48px !important;
}

.lg-avatar {
  width: 120px !important;
  height: 120px !important;
}

.decision-tag-element {
  background: $light;
  border-radius: 1rem;
  padding: 0.375rem 0.75rem;
  line-height: 1rem;
}

.sticky-help-button {
  position: fixed;
  right: 12px;
  bottom: $spacer;
}

//Tag in field overrides
.rbt-token {
  background-color: $light;
  color: $dark;
  transition: background-color 0.1s ease;

  &.rbt-token-removeable {
    overflow: hidden;
  }

  &:hover {
    background-color: shade-color($light, 10%);
  }
}

.rbt-token .rbt-token-remove-button {
  font-size: 1.25rem;
  padding: 3px 5px;
}

.rbt-input-multi input::-webkit-input-placeholder {
  color: $gray;
  opacity: 1;
}

.rbt-input-multi input::-moz-placeholder {
  color: $gray;
  opacity: 1;
}

.rbt-input-multi input:-ms-input-placeholder {
  color: $gray;
  opacity: 1;
}

.rbt-input-multi input::-ms-input-placeholder {
  color: $gray;
  opacity: 1;
}

.rbt-input-multi input::placeholder {
  color: $gray;
  opacity: 1;
}

.rbt-input-wrapper {
  .rbt-close {
    z-index: unset;
  }
}

//mentions
.mentions-suggestions-list {
  max-height: 134px;
  background-color: white;
  overflow: auto;
  position:absolute;
  left: 0;
  bottom: 100%;
  border: 1px solid $gray-200;
  border-radius: 0.75rem;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.1);
  transition: 0.3s;
}

.mentions-suggestions-item {
  white-space: nowrap;
  padding: 6px 12px;
  transition: all 0.3s ease;

  &:hover {
    background-color: $light;
  }

  &:focus {
    background-color: $dark;
  }
}

.mentions-display {
  background-color: $secondary;
  position:relative;
  top:1px;
}

.decision-pending-pill {
  padding: 1rem;
}

.center-container-elements {
  width: 70vw;
}

.tree_wizard, .historical-tree-wizard, .report_view,
.center-container-elements {
  max-width: 598px;
}

.words-list-link {
  overflow: hidden;
  word-break: break-all;

  a.truncated {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.tree_wizard-side_panel {
  max-width: 405px;
  min-width: 300px;
}

.report_view-side_panel {
  max-width: 405px;
  min-width: 300px;
}

.historical-decision-side_panel {
  max-width: 598px;

  .tree_wizard-side_panel {
    max-width: initial;
    min-width: initial;
  }
}

.ms-2-negative {
  margin-left: -0.75rem;
}
.ms-3-negative {
  margin-left: -1.7em;
}

.mt-2-negative {
  margin-top: -0.75rem;
}
.mt-3-negative {
  margin-top: -1.8rem;
}

.me-2-negative {
  margin-right: -0.75em;
}
.me-3-negative {
  margin-right: -1.7em;
}

.white-space-nowrap {
  white-space: nowrap;
}

.required-muted-text {
  font-size: $h5-font-size;
  font-weight: initial;
}

.react-transform-component {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.fw-bolder {
  font-weight: $headings-font-weight !important;
}

.d-initial {
  display: initial !important;
}

.mw-fit-content {
  min-width: fit-content;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.form-control{
  height: calc(1.5em + 1.5625rem + 2px);
  color: $dark;

  &.is-invalid {
    background: none !important;
  }
}

textarea.no-resize {
  resize: none;
}

.switch-shadow-none {
  box-shadow: none !important;

  .react-switch-handle {
    box-shadow: none !important;
  }
}

.mw-max-content {
  min-width: max-content;
}

.date-picker {
  width: 100%;
}

.dropdown-menu {
  min-width: auto;
}

.color-inherit {
  color: inherit;
}

.dsights-icon {
  display: block;
  background-repeat:no-repeat;
  background-size: 17px;
  padding-left: 2rem;

  &--primary {
    @extend .dsights-icon;
    background-image: url("../../../../assets/images/D-Sight_icon_primary_24x24.svg");
  }

  &--light {
    @extend .dsights-icon;
    background-image: url("../../../../assets/images/D-Sight_icon_white_24x24.svg");
  }

  &--gray {
    @extend .dsights-icon;
    background-image: url("../../../../assets/images/D-Sight_icon_gray_24x24.svg");

    &:hover {
      background-image: url("../../../../assets/images/D-Sight_icon_primary_24x24.svg");
    }
  }

  &.h-16 {
    background-size: 16px;
  }

  &.h-17 {
    background-size: 17px;
    font-size: 14px;
  }

  &.h-24 {
    background-size: 24px;
    font-size: 20px;
    padding-left: 1.7rem;
  }

  &.h-20 {
    background-size: 20px;
    font-size: 20px;
    padding-left: 20px;
    width: 0;
  }
}

.top-72 {
  top: 72px;
}

.dsights-img {
  width: 20px;
  height: 20px;
}

.dsight-powered-logo {
  display: initial;
  background-repeat:no-repeat;
  position: fixed !important;
  bottom: 12px;
  right: 24px;
  background-image: url("../../../../assets/images/D-Sight_logo_36x80.svg");
  padding-top: 38px;
  padding-right: 80px;

  &--link {
    @extend .dsight-powered-logo;
    margin-left: 0;
  }
}

.two-lines-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 36px;

  &.safari {
    line-height: normal;
  }
}

.three-lines-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip-inner {
  background-color: $dark !important;
  line-height: $line-height-sm;
}

.overflow-visible {
  overflow: visible !important;
}
.overflow-x-visible {
  overflow-x: visible !important;
}

#fa-comment {
  left: 320px;
  position: absolute;
  top: 24px;
}

.on-hover-secondary {
  &:hover,
  &:active,
  &:focus {
    background-color: $dropdown-link-hover-bg;
  }
}

.on-load-opacity {
  opacity: 0.3;
}

.fa-layers.fa-3x {
  font-size: 36px;
}

// https://deploy-preview-38152--twbs-bootstrap.netlify.app/docs/5.3/migration/#utilities-1
.text-muted {
  color: $text-muted !important;
}

.square-24 {
  height: 24px;
  width: 24px;
}

.small-spinner {
  .spinner-border {
    --bs-spinner-width: 1.125rem;
    --bs-spinner-height: 1.125rem;
  }
}

button#sort-by-dropdown, button#product-dropdown {
  height: 21px;
}

.rounded {
  border-radius: $btn-border-radius !important;
}

.rounded-12 {
  border-radius: $border-radius !important;
}

.border-top-start-90 {
  border-top-left-radius: 0 !important;
}

@media only screen and (max-width: 597px) {
  .historical-tree-wizard, .historical-decision-side_panel {
    max-width: 100vw;
  }
}

@media only screen and (max-width: 767px) {
  .center-container-elements {
    max-width: unset;
    width: 96vw;
  }
}

@media only screen and (min-width: 992px) {
  .center-container-block {
    padding-right: 160px;
  }
}

@media only screen and (min-width: 1200px) {
  .tree_wizard {
    width: 50vw;
    max-width: none;
  }
  .historical-tree-wizard {
    width: calc(50vw - 36px);
    max-width: none;
  }
  .historical-decision-side_panel {
    width: calc(50vw - 24px);
    max-width: none;
  }
}

@media only screen and (max-width:1440px) {
  .pe-wrapper  {
    padding-right: 0 !important;
  }
}
