.groups-tile {
  border-radius: $btn-border-radius;
}
.groups-menu {
  max-width: 296px;
  height: fit-content;
  .list-group {
    border-radius: unset;
    .list-group-item {
      color: $muted;
      &.active {
        background-color: white;
        color: $dark;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .groups-container {
    height: calc(100vh - 120px);
  }
}
