$body-color: $dark;

$font-size-base: 0.875rem;
$h1-font-size: 1.375rem; // 22px
$h2-font-size: 1.375rem; // 22px
$h3-font-size: 1.125rem; // 18px
$h4-font-size: 1.125rem; // 18px
$h5-font-size: 0.875rem; // 14px
$h6-font-size: 0.75rem; // 12px

$bold: 500;

$border-radius: 0.75rem;
$btn-border-radius: 10px;
$btn-border-width: 0;
$btn-font-weight: 500;
$input-btn-padding-y: 0.78125rem;
$input-btn-padding-y-sm: 0.40625rem;
$input-btn-padding-x-sm: 0.75rem;
$input-btn-font-size-sm:  0.875rem;

$spacer: 1.5rem;
$nav-link-color: $darken-btn-secondary-text;
$nav-link-hover-color: $darken-btn-secondary-text;
$nav-link-transition: color 0s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;

$dropdown-item-padding-y: 0.25rem;
$dropdown-item-padding-x: $spacer;

$dropdown-link-active-color: $white;

$grid-gutter-width: $spacer;

$tooltip-border-radius: 0.375rem;

$badge-font-size: 1em;
$badge-font-weight: 400;
$badge-color: $light;
$badge-padding-y: .55em;
$badge-padding-x: .65em;
$badge-border-radius: $border-radius;

$alert-padding-y: 0.75rem;
$alert-padding-x: 0.75rem;
$alert-bg-scale: -80%;
$alert-border-scale: -70%;
$alert-color-scale: 50%;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1201px //1400px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1141px //1320px
);

$line-height-sm: 1.125rem;
$line-height-lg: 1.5rem;

$dropdown-divider-margin-y: 0.4rem;
