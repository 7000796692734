.quill {
  &.primary-box-shadow {
    // styles to follow input ficus styling
    .ql-bubble {
      box-shadow: 0 0 0 0.25rem rgba(36, 135, 191, 0.25);
      border: 1px solid #92c3df;
    }
  }

  &.one-line {
    .ql-editor {
      min-height: 48px !important;
    }
  }

  .ql-container {
    font-family: inherit;
    font-size: inherit;
    color: $dark;

    .ql-editor {
      line-height: 24px;
      overflow: initial;
      min-height: 104px;

      &.ql-blank::before {
        font-size: 0.875rem;
        color: $gray;
        font-family: "Montserrat", "omnes-pro", "Open Sans", sans-serif;
        font-weight: normal;
        font-style: normal;
      }
    }

    &.ql-snow {
      border-radius: 0 0 10px 10px !important;
    }
  }

  .ql-toolbar {
    &.ql-snow {
      border-radius: 10px 10px 0 0 !important;
    }
  }
  .ql-snow {
    a {
      color: $primary;
    }
  }
}

.ql-bubble {
  border: 1px solid $gray-border;
  border-radius: 0.75rem;

  a {
    white-space: break-spaces !important;
    &:before, &:after {
      display: none;
    }
  }
}

.note_paragraph  {
  overflow-wrap: break-word;
  word-break: break-word;

  p {
    margin-bottom: 0;
  }

  ol, ul, dl {
    margin-bottom: 0 !important;
  }

  a {
    display: inline;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  table {
    border-color: $light-gray;
    td {
      padding: 0.4em;
      height: 33px;
    }
  }

  &.ql-editor {
    padding: 0;
    white-space: inherit;
  }
}

.ql-editor {
  a {
    cursor: pointer;
  }

  ol, ul {
    padding-left: 0.70rem !important;
  }
}

.playbook-notes-container {
  .quill > .ql-container > .ql-editor{
    overflow: visible;
  }
}

#approval_comments {
  .quill > .ql-container > .ql-editor {
    &:focus {
      border: 1px #92c3df;
      border-radius: 0.75rem;
      box-shadow: 0 0 0 0.3rem rgb(36 135 191 / 25%);
      outline: 0;
    }
  }
}

#driver_answer {
  .quill > .ql-container > .ql-editor {
    min-height: 48px;
  }
}
